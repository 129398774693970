import { initHomeSearch } from "../components/elm_search"
import { addToCartEvent } from "../helpers/ga4_helper"
import { loadBackgroundImages } from "../helpers/image_loader"
import { ENTRY, prefetchEntry } from "../helpers/prefetch"

const setupBuyVoucherForm = (): void => {
  const form = <HTMLFormElement>document.getElementById("js-buy-voucher")
  const amount = <HTMLInputElement>form.querySelector("input[name=amount]")

  amount.addEventListener("focus", () => prefetchEntry(ENTRY.VOUCHER_BOOKINGS))

  form.addEventListener("submit", e => {
    e.preventDefault()

    amount.value = Math.max(+amount.value, +amount.min).toString()
    addToCartEvent({
      item_category: "Monetary Voucher",
      item_name: (+amount.value).toFixed(2),
      price: +amount.value,
      quantity: 1
    }, +amount.value)

    form.submit()
  })
}

loadBackgroundImages()
setupBuyVoucherForm()
initHomeSearch()
